<div class="inner-content converse-brand row">
    <div class="converse-brand__padding"></div>
    <div class="converse-brand__heading">
        <svg height="300px"
	     xmlns="http://www.w3.org/2000/svg" width="42" height="42"><g stroke-linecap="round" stroke-linejoin="round"><path d="M4 4h34v26H12.002L4 36.89z" fill="#083160" stroke="#2b9934" stroke-width="2"/><path d="M8.521 8.5h13" stroke="#fff" stroke-width="3"/><path d="M20.479 14.5h13" fill="#edd400" stroke="#edd400" stroke-width="3"/><path d="M8.479 20.5h13" stroke="#fff" stroke-width="3"/><path d="M20.521 26.5h13" fill="#edd400" stroke="#edd400" stroke-width="3"/></g>
        </svg>
        <span class="converse-brand__text">
            <p class="byline">ConabChat</p>
        </span>
    </div>
</div>
